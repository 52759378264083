import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Credentials} from '../../shared/models/auth/Credentials';

const credentialsKey = 'credentials-CRM-2-0';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  defaultTokenType = 'Bearer';
  private _credentials: Credentials | null = null;

  constructor(private http: HttpClient) {
    const savedCredentials =
      sessionStorage.getItem(credentialsKey) ||
      localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   *
   */
  getToken() {
    if (this._credentials != null) {
      return this._credentials.token;
    }
    return null;
  }

  /**
   *
   */
  getAuthHeader() {
    if (this._credentials != null) {
      return `${this.defaultTokenType} ${this._credentials.token}`;
    }
    return null;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      credentials.rememberMe = remember;
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  setRoles() {
    return this.http.get(`user/roles`).pipe(
      tap((res: any) => {
        this._credentials.user.roles = res;
      }),
    );
  }
}
