import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../services/authentication.service";
import {NgxSpinnerService} from "ngx-spinner";
import {finalize} from "rxjs/operators";
import {Credentials} from "../../shared/models/auth/Credentials";
import {untilDestroyed} from "../../core";
import {CredentialsService} from "../services/credentials.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{
  loginFormSubmitted = false;
  isLoginFailed = false;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    remember: new FormControl(true)
  });

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private credentialsService: CredentialsService
  ) { }

  ngOnInit(): void {
    if (this.credentialsService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  onSubmit(): void {
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.spinner.show(undefined, {
      type: 'line-scale',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.65)',
      color: '#fff',
      fullScreen: true,
    });

    const login$ = this.authenticationService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.spinner.hide();
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (credentials: Credentials) => {
          this.router.navigate(
            [this.route.snapshot.queryParams.redirect || '/'],
            { replaceUrl: true }
          );
        },
        (error: any) => {
          this.isLoginFailed = true;
        }
      );
  }

  ngOnDestroy(): void {
  }
}
