<div class="wrapper">
  <div class="sidebar" data-color="primary" data-background-color="white" data-image="./assets/img/sidebar-3.jpg">
    <app-sidebar (logoutEvent)="logout()"></app-sidebar>
    <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-3.jpg)"></div>
  </div>
  <div class="main-panel">
    <app-navbar (logoutEvent)="logout()"></app-navbar>
    <div class="main-content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div>
    </div>
    <div *ngIf="isMaps('maps')">
      <app-footer></app-footer>
    </div>
  </div>
  <p-toast [baseZIndex]="1000"></p-toast>
</div>
