<div class="main-content d-flex align-items-center vh-100">
  <div class="container-fluid ">
    <div class="row d-flex justify-content-center ">
      <div class="col-lg-5 col-xl-3">
        <div class="alert alert-danger " role="alert"
             *ngIf="isLoginFailed"
             (close)="isLoginFailed = false">
              Błędny login lub hasło
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-5 col-xl-3">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">Zaloguj</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="loginForm">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="material-icons">email</i></span>
                      </div>
                      <input class="form-control" placeholder="Adres e-mail" type="email" formControlName="email" required>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="material-icons">lock</i></span>
                      </div>
                      <input class="form-control" placeholder="Hasło" type="password" formControlName="password" required>
                    </div>
                  </div>
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-12">-->
<!--                  <div class="custom-control custom-control-alternative custom-checkbox"-->
<!--                       style="padding: 10px 0 0 42px;">-->
<!--                    <input class="custom-control-input" id="remember" type="checkbox" formControlName="remember">-->
<!--                    <label class="custom-control-label" for="remember">-->
<!--                      <span class="text-muted">Zapamiętaj mnie</span>-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="row d-flex justify-content-center pt-2">
                <button mat-button type="submit" class="btn btn-primary text-center" (click)="onSubmit()">Zaloguj</button>
              </div>
            </form>
            <ngx-spinner><span class="text-white">Logowanie...</span></ngx-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
