import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import {AuthenticationService} from "../../auth/services/authentication.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authenticationService.getToken() === null) {
      return next.handle(req);
    }

    if (this.authenticationService.getToken() != null) {
      req = this.addToken(req);
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //  Response is OK
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // Unauthorized, redirect to login page
              this.authenticationService.logout();
              this.router.navigate(["login"], { replaceUrl: true });
              return of(err);
            }
          }
        }
      )
    );
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `${this.authenticationService.getAuthHeader()}`,
      },
    });
  }
}
