import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Role } from '../../shared/constants/role.constants';
import { CredentialsService } from '../../auth/services/credentials.service';
import { User } from '../../shared/models/User';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    permission?: string[];
}
export const ROUTES: RouteInfo[] = [
    { path: '/home', title: 'Kokpit',  icon: 'home', class: '' },
    { path: '/calendar', title: 'Kalendarz',  icon: 'event', class: '' },
    { path: '/company', title: 'Firmy',  icon: 'business', class: '' },
    { path: '/salesOpportunity', title: 'Szanse sprzedaży',  icon: 'monetization_on', class: '' },
    // { path: '/report', title: 'Raporty',  icon: 'show_chart', class: '' },
    { path: '/user', title: 'Użytkownicy',  icon: 'people', class: '', permission: [Role.ADMIN] },
    { path: '/userTarget', title: 'Cele pracowników',  icon: 'stacked_line_chart', class: '', permission: [Role.ADMIN, Role.COORDINATOR] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  user?: User;

  @Output()
  logoutEvent = new EventEmitter<void>();

  constructor(
    private credentialsService: CredentialsService,
  ) { }

  ngOnInit(): void {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.user = this.credentialsService.credentials.user;
  }

  isMobileMenu(): boolean {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  }

  logout(): void{
    this.logoutEvent.emit();
  }

}
