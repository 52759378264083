import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FLASH_MESSAGE } from '../../constants/flash-message.constants';

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {

  constructor(private messageService: MessageService) { }

  successMessage(message = FLASH_MESSAGE.SUCCESS.CREATE, life = 3000, title = 'Sukces'): void {
    this.messageService.add({
        severity: 'success',
        summary: title,
        life,
        detail: message
      });
  }

  warningMessage(message, life = 3000, title = 'Uwaga'): void {
    this.messageService.add({
      severity: 'warn',
      summary: title,
      life,
      detail: message
    });
  }

  errorMessage(message = FLASH_MESSAGE.ERROR, life = 3000, title = 'Błąd'): void {
    this.messageService.add({
      severity: 'error',
      summary: title,
      life,
      detail: message
    });
  }

  infoMessage(message, life = 3000, title = 'Informacja'): void {
    this.messageService.add({
      severity: 'info',
      summary: title,
      life,
      detail: message
    });
  }

  clear(): void {
    this.messageService.clear();
  }
}
