import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { ComponentsModule } from "./components/components.module";
import { RouterModule } from "@angular/router";
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { LoginComponent } from './auth/login/login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsModule } from "ngx-permissions";
import { CoreModule} from "./core";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from '@angular/material/button';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService} from 'primeng/api';
import { FlashMessageService } from './shared/services/flash-message/flash-message.service';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';

registerLocaleData(localePl);

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    EmptyLayoutComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentsModule,
    CoreModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forRoot(),
    MatButtonModule,
    FormsModule,
    ToastModule
  ],
  providers: [
    MessageService,
    FlashMessageService,
    ConfirmationService,
    { provide: LOCALE_ID, useValue: 'pl-PL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
